import { Base64 } from 'js-base64';

export const covertBase64ToFile = (base64: string, fileName: string = 'image.png') => {
  const binaryData = Base64.toUint8Array(base64);
  
  const blob = new Blob([binaryData], { type: 'image/png' });

  const file = new File([blob], fileName, { type: blob.type });

  return file
}