import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { useUserStore } from './store/userStore'
import { useLanguageStore } from '@/store/languageStore'
import { ElLoading } from 'element-plus';

import PrimeVue from 'primevue/config'
import Toast from 'vue-toastification'
import './style.scss'
import 'vue-toastification/dist/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap-icons/font/bootstrap-icons.css'
const pinia = createPinia()
const app = createApp(App)

app.use(router).use(pinia).use(Toast).use(PrimeVue).use(ElLoading).mount('#app')

const userStore = useUserStore()
userStore.loadFromCookies()
const languageStore = useLanguageStore()
const defaultLanguages = ['en', 'ru'] // Установите нужные языки по умолчанию
for (const lang of defaultLanguages) {
  languageStore.setLanguage(lang)
}
